import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from 'components_v3/Loader';
import { useProfileQuery } from 'hooks/account';

export const PrivateRoute = ({ children }) => {
  const profileQuery = useProfileQuery({ retry: false });

  if (profileQuery.isLoading) {
    return <Loader />;
  }

  const isAuthenticated = !isEmpty(profileQuery.data);

  // Redirecting to login is handled inside the api client
  return isAuthenticated ? children : null;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
