export const SORT_FIELDS = {
  NAME: 'name',
  CREATED: 'created',
  MODIFIED: 'modified',
  SIZE: 'size',
  DURATION: 'duration',
  SIMILARITY: 'similarity',
};

export const SORT_ORDERS = {
  ASC: 'asc',
  DESC: 'desc',
};

export const DEFAULT_SORT = {
  field: SORT_FIELDS.MODIFIED,
  order: SORT_ORDERS.DESC,
};
