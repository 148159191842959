import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

const ModalStateManager = ({ renderLauncher: LauncherContent, renderContent: ModalContent }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {!ModalContent || typeof document === 'undefined'
        ? null
        : open && createPortal(<ModalContent open={open} setOpen={setOpen} />, document.body)}
      {LauncherContent && <LauncherContent open={open} setOpen={setOpen} />}
    </>
  );
};

ModalStateManager.propTypes = {
  renderLauncher: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
};

export default ModalStateManager;
