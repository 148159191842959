import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import supportedLanguages from '../supported-languages.json';

export const getSharedDomainPostfix = () => {
  const baseURL = import.meta.env.VITE_BASE_URL;
  return baseURL.substring(baseURL.indexOf('.'));
};

export const toLanguageCode = (locale) => {
  return locale.toLowerCase().replace('_', '-');
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(I18NextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'localStorage', 'cookie'],
      lookupQuerystring: 'locale',
      lookupCookie: import.meta.env.VITE_LANGUAGE_COOKIE_NAME,
      cookieDomain: getSharedDomainPostfix(),
      caches: ['localStorage', 'cookie'],
      convertDetectedLanguage: (lng) => (lng in supportedLanguages ? lng : 'en'),
    },
  });

export default i18n;
