import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { API_GET_CC_SESSION, API_SIGN_OUT, API_TOKEN_VERIFICATION } from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

export const useLogout = () => {
  const apiClient = useContext(ApiClientContext);

  // FIXME: this is for backward compatibility, should be fixed later.
  return useMutation(async () => {
    await apiClient(buildUrl(API_SIGN_OUT), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ last_visited_path: window.location.pathname }),
    });

    // Use this instead of useNavigate to prevent user clicks back button
    window.location.replace('/sign-in');
  }).mutateAsync;
};

export const useTokenValidation = (token) => {
  const url = buildUrl(API_TOKEN_VERIFICATION, [{ name: 'token', value: token }]);
  return useQuery({
    queryKey: ['tokenDetail', token],
    queryFn: async () => {
      const response = await fetch(url, { credentials: 'include' });
      if (!response.ok) {
        throw new Error('Invalid token');
      }
      return 'success';
    },
    enabled: !!token,
    retry: false,
  });
};

export const useCCSessionMutation = () => {
  const apiClient = useContext(ApiClientContext);
  return useMutation(async () => {
    const res = await apiClient(buildUrl(API_GET_CC_SESSION), {
      method: 'POST',
    });

    return res;
  });
};
