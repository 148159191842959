import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { API_GET_FEATURES } from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

export const useFeatures = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: ['accessible_features'],
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_GET_FEATURES));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};
