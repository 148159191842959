import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './index.scss';
import AppRoutesV2 from 'app-routes-v2';
import CheckSubdomainWrapper from 'components/common/FusionAuthWrapper';
import Loader from 'components_v3/Loader';
import { ApiClientProvider } from 'context/api-client';
import retryRule from 'utils/retry';

import { initializeAnalytics } from './google-analytics';
import './i18n';

import 'react-toastify/dist/ReactToastify.css';
import { initializeGoogleTagManager } from 'google-tag-manager';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

ReactModal.setAppElement('#root');

const App = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [queryClient, setQueryClient] = useState(null);

  useEffect(() => {
    const setupApp = () => {
      // Initialize analytics
      initializeAnalytics();
      initializeGoogleTagManager();

      // Create QueryClient
      const client = new QueryClient({
        defaultOptions: {
          queries: {
            retry: retryRule,
          },
        },
      });
      setQueryClient(client);

      setIsInitialized(true);
    };

    setupApp();
  }, []);

  if (!isInitialized || !queryClient) return <Loader />;

  return (
    <>
      <ToastContainer theme='dark' />
      <ApiClientProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <CheckSubdomainWrapper>
            <Router>
              <AppRoutesV2 />
            </Router>
          </CheckSubdomainWrapper>
        </QueryClientProvider>
      </ApiClientProvider>
    </>
  );
};

createRoot(document.getElementById('root')).render(<App />);
