import { Popover, PopoverContent } from '@carbon/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const PopoverWrapper = ({ content, children, align }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      open={isPopoverOpen}
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
      align={align || 'bottom'}
      className='popover'
    >
      {children}
      <PopoverContent className='popover__content'>{content}</PopoverContent>
    </Popover>
  );
};

PopoverWrapper.propTypes = {
  content: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
};

export default PopoverWrapper;
