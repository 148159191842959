import { format, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

export const displaySnapshotTime = (isoTimestamp, locale) => {
  const date = parseISO(isoTimestamp);
  const dateFormat = 'MMMM d, yyyy'; // Format: "July 2, 2024"
  const selectedLocale = locale === 'fr' ? fr : enUS;

  return format(date, dateFormat, { locale: selectedLocale });
};

export const displaySnapshotDetailTime = (isoTimestamp, locale) => {
  const date = parseISO(isoTimestamp);
  const dateTimeFormat = "MMMM d yyyy, 'at' h:mm a";
  const selectedLocale = locale === 'fr' ? fr : enUS;

  return format(date, dateTimeFormat, { locale: selectedLocale });
};
