const retryRule = (failureCount, error) => {
  if (failureCount >= 2) {
    return false;
  }
  if (error.status && +error.status >= 400 && +error.status < 500) {
    return false;
  }
  return true;
};

export default retryRule;
