import TagManager from 'react-gtm-module';

export function initializeGoogleTagManager() {
  const GTM_ID = import.meta.env.VITE_GTM_ID;

  // Inject the Google Analytics script tag with the correct ID
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
}
