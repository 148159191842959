import { Help, Menu } from '@carbon/icons-react';
import {
  Header,
  HeaderName,
  Theme,
  SkipToContent,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderContainer,
  OverflowMenu,
  OverflowMenuItem,
  TabList,
  Tab,
  Tabs,
  Button,
} from '@carbon/react';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AppLogo from 'assets/v3/app_logo.png';
import HorizontalLogo from 'assets/v3/horizontal_logo.svg';
import { SIDEBAR_NEW_CHAT_V2 } from 'constants/constants';
import { buildRoutineUrl, buildConversationUrl } from 'constants/routes';
import { NavItem } from 'constants/variableConstants';
import { useProfileQuery } from 'hooks/account';
import { useCCSessionMutation } from 'hooks/authentication';
import { toLanguageCode } from 'i18n';

import LanguageSelector from './LanguageSelector';
import { Logo } from './Logo';
import MenuProfile from './MenuProfile';
import './Navbar.scss';

const Navbar = ({
  activeNavItem,
  setActiveNavItem,
  setShowSidebar,
  setCollapseConversationSidebar,
  collapseConversationSidebar,
  options,
}) => {
  const {
    showCompanyLogo = true,
    showDocs = true,
    showAccount = true,
    showSideNav = true,
  } = options || {};
  const navigate = showSideNav || showCompanyLogo || showAccount ? useNavigate() : null;
  const { t, i18n } = useTranslation();
  const profileQuery = showSideNav || showCompanyLogo || showAccount ? useProfileQuery() : null;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
    setIsMenuOpen(false);
    if (collapseConversationSidebar) {
      setCollapseConversationSidebar(false);
    }
    setShowSidebar(true);

    switch (navItem) {
      case NavItem.Conversations:
        navigate(buildConversationUrl(SIDEBAR_NEW_CHAT_V2));
        break;
      case NavItem.Routines:
        navigate(buildRoutineUrl(SIDEBAR_NEW_CHAT_V2));
        break;
    }
  };

  const CCSessionMutation = useCCSessionMutation();

  const handleManageAccountClick = async () => {
    const res = await CCSessionMutation.mutateAsync();
    if (res.ok) {
      window.open(import.meta.env.VITE_CC_URL);
    } else {
      toast.error(t('You do not have managing permission!'));
    }
  };

  return (
    <div id='navbar-container' className='container'>
      <HeaderContainer
        render={() => (
          <>
            <Theme theme='g100'>
              <Header aria-label='Skydeck'>
                <SkipToContent />
                <div className='menu-container'>
                  {showSideNav && (
                    <OverflowMenu
                      menuOptionsClass='menu-options'
                      id='navbar-menu'
                      renderIcon={Menu}
                      open={isMenuOpen}
                      onOpen={() => setIsMenuOpen(true)}
                      onClose={() => setIsMenuOpen(false)}>
                      <OverflowMenuItem
                        itemText={t('Conversations')}
                        onClick={() => handleNavItemClick(NavItem.Conversations)}
                        isSelected={activeNavItem === NavItem.Conversations}
                      />
                      <OverflowMenuItem
                        itemText={t('Routines')}
                        onClick={() => handleNavItemClick(NavItem.Routines)}
                        isSelected={activeNavItem === NavItem.Routines}
                      />
                    </OverflowMenu>
                  )}
                </div>
                {showCompanyLogo && (
                  <Logo
                    className='company-logo'
                    logoUrl={profileQuery.data?.data?.account?.logo ?? AppLogo}
                    altText={t('Company logo')}
                  />
                )}
                <HeaderName
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://skydeck.ai/'
                  prefix=''>
                  <div className='logo-group'>
                    <img className='app-logo' src={AppLogo} alt={t('SkyDeck.ai GenStudio')} />
                    <img
                      className='horizontal-logo'
                      src={HorizontalLogo}
                      alt={t('SkyDeck.ai GenStudio')}
                    />
                  </div>
                </HeaderName>
                {showSideNav && (
                  <div className='tabs-container'>
                    <Tabs
                      selectedIndex={activeNavItem}
                      onChange={({ selectedIndex }) => handleNavItemClick(selectedIndex)}>
                      <TabList aria-label='navbar-tabs'>
                        <Tab>{t('Conversations')}</Tab>
                        <Tab>{t('Routines')}</Tab>
                      </TabList>
                    </Tabs>
                  </div>
                )}
                <HeaderGlobalBar>
                  {showAccount && profileQuery.data?.data?.is_owner_or_admin && (
                    <Button className='manage-account-btn' onClick={handleManageAccountClick}>
                      {t('Manage account')}
                    </Button>
                  )}
                  <LanguageSelector />
                  {showDocs && (
                    <div className='documentation'>
                      <HeaderGlobalAction
                        aria-label={t('SkyDeck.ai documentation')}
                        onClick={() =>
                          window.open(`https://docs.skydeck.ai/v/${toLanguageCode(i18n.language)}`)
                        }>
                        <Help size={20} aria-label='Help' />
                      </HeaderGlobalAction>
                    </div>
                  )}

                  {showAccount && <MenuProfile />}
                </HeaderGlobalBar>
              </Header>
            </Theme>
          </>
        )}
      />
    </div>
  );
};

Navbar.propTypes = {
  activeNavItem: PropTypes.number,
  setActiveNavItem: PropTypes.func,
  setShowSidebar: PropTypes.func,
  setCollapseConversationSidebar: PropTypes.func,
  collapseConversationSidebar: PropTypes.bool,
  options: PropTypes.object,
};

export default memo(Navbar);
