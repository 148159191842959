import { Button, Theme } from '@carbon/react';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './NotFound.scss';

import text_404 from 'assets/404.svg';
import astronault from 'assets/astronault.svg';
import orange_glow from 'assets/orange_glow.png';
import starry_bg from 'assets/starry_bg.png';
import { HOME_PATH } from 'constants/routes';
import { SKYDECK_LANDING_PAGE } from 'constants/constants';
import { getSubdomain } from 'utils/utils';

export const NotFound = () => {
  const { t } = useTranslation();

  const backToHomeLink = getSubdomain() === 'share' ? SKYDECK_LANDING_PAGE : HOME_PATH;

  return (
    <div className='not-found'>
      <img src={starry_bg} className='not-found__background' />
      <Theme theme='g90'>
        <div className='not-found__home-button'>
          <Button as={Link} to={backToHomeLink}>
            {t('Back to home')}
          </Button>
          <img src={orange_glow} alt={t('Orange glow')} className='not-found__home-button-image' />
        </div>

        <div className='not-found__image'>
          <img src={astronault} alt={t('Astronaut')} className='not-found__image-astronaut' />
          <img src={text_404} alt={t('404')} />
        </div>

        <h1 className='not-found__text'>
          {t('Oops!')}
          <p className='not-found__text-sub'>{t('Page not found')}</p>
        </h1>
      </Theme>
    </div>
  );
};
