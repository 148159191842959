import moment from 'moment-timezone';

import { PATH_SIGN_IN, MAX_OUTPUT_TOKENS, BREAKPOINTS } from 'constants/constants';

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

let baseUrl = import.meta.env.VITE_BASE_URL;

export const changeBaseUrl = (url) => (baseUrl = url);

export const buildUrl = (apiPath, placeholders = []) => {
  let path = apiPath;

  placeholders.forEach((placeholder) => {
    const { name, value } = placeholder;
    const placeholderPattern = new RegExp(`:${name}`, 'g');
    path = path.replace(placeholderPattern, value);
  });

  return `${baseUrl}${path}`;
};

export const persistLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
  return value;
};

export const generateSenderIconBgColor = (sender) => {
  const str = `${sender?.name}${sender?.id}`;

  return generateIconBgColor(str);
};

export const generateIconBgColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    value = Math.max(Math.min(value, 200), 50); // Ensure each color component is between 50 and 200, so the background is not too light or dark
    color += ('00' + value.toString(16)).slice(-2);
  }
  return color;
};

export const readLocalStorage = (key) => JSON.parse(localStorage.getItem(key));

export const useBypassFA = () => import.meta.env.VITE_BYPASS_FA == 'true';

export const isUserSignInPage = () =>
  window.location.hostname.startsWith('user.') ||
  (window.location.hostname.includes('localhost') &&
    window.location.search.includes('user-sign-in'));

export const testableSignInPagePath = () =>
  window.location.hostname.includes('localhost') ? `${PATH_SIGN_IN}?user-sign-in` : PATH_SIGN_IN;

export const TIME_ZONES = moment.tz
  .names()
  .map((tz) => {
    let offset = moment.tz(tz).utcOffset();

    if (offset % 60 === 0) {
      // Check if minutes part of offset is zero and offset is positive or zero
      let formattedOffset = moment.tz(tz).format('Z');
      let label = `(UTC ${formattedOffset}) ${tz} `;

      return { label: label, value: formattedOffset, offset: offset, name: tz };
    }

    return null;
  })
  .filter((item) => item != null)
  .sort((a, b) => a.offset - b.offset); // Sort by offset

export const HOUR_VALUES = Array.from({ length: 12 }, (v, i) => {
  let hour = (i + 1).toString().padStart(2, '0'); // padStart will add a leading 0 if the hour is less than 10

  return {
    label: hour,
    value: hour,
  };
});

export const MINUTE_VALUES = Array.from({ length: 60 }, (v, i) => {
  let minute = i.toString().padStart(2, '0'); // padStart will add a leading 0 if the minute is less than 10

  return {
    label: minute,
    value: minute,
  };
});

export const formatTime = (value, timezone = null) => {
  let time = moment(value, 'YYYY-MM-DD HH:mm:ssZ');
  if (timezone) {
    time = time.tz(timezone);
    return time.format('YYYY-MM-DD h:mm A Z');
  }

  const tz = moment.tz.guess();
  time = time.tz(tz);
  return time.format('YYYY-MM-DD h:mm A');
};

export const repeatTypes = [
  {
    value: 'minutes',
    text: 'Minute(s)',
  },
  {
    value: 'hours',
    text: 'Hour(s)',
  },
  {
    value: 'days',
    text: 'Day(s)',
  },
  {
    value: 'months',
    text: 'Month(s)',
  },
];

export const calculateMaxInputToken = (model) => {
  return model
    ? MAX_OUTPUT_TOKENS > model.context_window
      ? Math.round(model.context_window / 4)
      : Math.round(model.context_window - MAX_OUTPUT_TOKENS)
    : 0;
};

export const getSubdomain = () => {
  const host = window.location.hostname;
  const parts = host.split('.');
  if (parts.length == 3 || (parts.length == 2 && parts[1] == 'localhost')) {
    return parts[0];
  }
  return null;
};

export const getByteSize = (str) => {
  return new Blob([str]).size;
};

export const truncateToByteSize = (str, maxBytes) => {
  const enc = new TextEncoder();
  const encoded = enc.encode(str);
  if (encoded.length <= maxBytes) return str;

  const decoder = new TextDecoder('utf-8');
  return decoder.decode(encoded.slice(0, maxBytes)) + '\n\n[Content truncated due to size]';
};

export const getAbsoluteURL = (url) => {
  const isAbsoluteOrDataURL = url.startsWith('http') || url.startsWith('data:');

  if (isAbsoluteOrDataURL) {
    return url;
  }

  return `${import.meta.env.VITE_BASE_URL}${url}`;
};

export const createBreakpointClass = (currentWidth, targetBreakpoint, viewportDirection) => {
  // Validate direction
  if (!['up', 'down'].includes(viewportDirection)) {
    return '';
  }

  // Validate breakpoint
  if (!BREAKPOINTS[targetBreakpoint]) {
    return '';
  }

  const breakpointWidth = BREAKPOINTS[targetBreakpoint];

  // Check if width is in correct range based on direction
  if (viewportDirection === 'up' && currentWidth >= breakpointWidth) {
    return `breakpoint-up-${targetBreakpoint}`;
  }

  if (viewportDirection === 'down' && currentWidth <= breakpointWidth) {
    return `breakpoint-down-${targetBreakpoint}`;
  }

  return '';
};

export const createBreakpointClasses = (width, breakpointConfigs) => {
  return breakpointConfigs
    .map(([breakpoint, direction]) => createBreakpointClass(width, breakpoint, direction))
    .filter(Boolean)
    .join(' ');
};
