import { ErrorFilled } from '@carbon/icons-react';
import { Theme } from '@carbon/react';
import PropTypes from 'prop-types';
import React from 'react';

import './GeneralError.scss';
import { classNames } from 'utils/utils';

import Navbar from './Navbar';

const GeneralError = ({ className, header, errorMessage, actions }) => {
  return (
    <>
      <Navbar
        options={{
          showCompanyLogo: false,
          showDocs: false,
          showAccount: false,
          showSideNav: false,
        }}
      />
      <Theme theme='g10' className={classNames('general-error', className)}>
        <div className='general-error__wrapper'>
          {header}
          <div className='general-error__content'>
            {!!errorMessage && (
              <p className='general-error__message'>
                <ErrorFilled size={20} />
                {errorMessage}
              </p>
            )}
            {actions}
          </div>
        </div>
      </Theme>
    </>
  );
};

GeneralError.propTypes = {
  className: PropTypes.func.string,
  header: PropTypes.node,
  errorMessage: PropTypes.string.isRequired,
  actions: PropTypes.node,
};

export default GeneralError;
