import { getSubdomain } from 'utils/utils';

export function initializeAnalytics() {  
  // Determine the Google Analytics ID based on the subdomain
  const subdomain = getSubdomain();
  const GA_ID = subdomain === 'share' ? import.meta.env.VITE_SNAPSHOT_GA_ID : import.meta.env.VITE_GA_ID;
  
  // Inject the Google Analytics script tag with the correct ID
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
  document.head.appendChild(script);
  
  // Initialize Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  
  script.onload = () => {
    gtag('js', new Date());
    gtag('config', GA_ID);
  };
}
