import {
  Checkmark,
  ChevronDown,
  ChevronUp,
  Close,
  CopyFile,
  Edit,
  Renew,
  TrashCan,
} from '@carbon/icons-react';
import { Button, TextArea, Tooltip } from '@carbon/react';
import { get, isEmpty, split } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import rehypeKatex from 'rehype-katex';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import { ReactComponent as ViewModeIcon } from 'assets/view_mode_icon.svg';
import { CodeBlock } from 'components_v3/markdown/code-block';
import { MemoizedReactMarkdown } from 'components_v3/markdown/memoized-react-markdown';
import { USER_MESSAGE } from 'constants/constants';
import { useProfileQuery } from 'hooks/account';
import { useCopyToClipboard, useDeleteMessage } from 'hooks/conversations';
import { prepareLatexContent } from 'utils/latexPreprocessor';
import { displayMessageTime } from 'utils/message';
import { generateSenderIconBgColor, getAbsoluteURL, getSubdomain } from 'utils/utils';

import ConversationMessageAssistant from './ConversationMessageAssistant';
import { ConversationMessageUserTool } from './ConversationMessageUserTool';
import DeleteMessageModal from './DeleteMessageModal';
import ModalStateManager from './ModalStateManager';
import { UploadedFileNameList } from './UploadedFileNameList';

import './ConversationMessage.scss';

export const ConversationMessage = ({
  message,
  streamingResponse,
  isError,
  isConversationOwner,
  isDeletionDisabled,
  isShown,
  snapshot_llms = [],
  onRegenerate = () => {},
  onEditAndRegenerate = () => {},
  shouldShowRegenerateButton = () => false,
  onEditMessage,
  previousMessage,
  currentUser,
  userMarkdownView,
  userLatexView,
  assistantMarkdownView,
  assistantLatexView,
  onBetterFormatClick,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const profileQuery = useProfileQuery();
  const profileData = get(profileQuery, 'data.data', {});
  const { t, i18n } = useTranslation();

  const snapshot_sender = (message.snapshot_data ?? {}).sender ?? {};
  const snapshot_model = (message.snapshot_data ?? {}).model ?? {};
  const snapshot_llm_list =
    Array.isArray(snapshot_llms) && snapshot_llms.length > 0
      ? snapshot_llms
      : snapshot_model.id
      ? [snapshot_model]
      : [];

  const sender = message.id === -1 ? profileData : message.sender ?? snapshot_sender;
  const senderAvatarText = split(sender.name, ' ', 2).map((word) => word[0] ?? '');
  const { isCopied, handleCopyToClipboard } = useCopyToClipboard();
  const isMessageOwner = profileData.id === sender.id;

  const deleteMessage = useDeleteMessage();

  const isSnapshotMessage = getSubdomain() === 'share';

  const formatToolContent = (message) => {
    if (message.schema)
      return (
        message.schema.title +
        '\n' +
        Object.entries(JSON.parse(message.content)).reduce(
          (prev, e) => prev + e[0] + ': ' + '```' + e[1] + '```' + '\n',
          '',
        )
      );
    return message.content;
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const messageThreshold = 500;
  const [shouldCollapse, setShouldCollapse] = useState(message?.content?.length > messageThreshold);

  useEffect(() => {
    setShouldCollapse(message.content.length > messageThreshold);
  }, [message.content]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const truncateContent = (content, maxLength) => {
    if (maxLength && content.length > maxLength) {
      return content.substring(0, maxLength) + '...';
    }
    return content;
  };

  const calculateRows = (text) => {
    const lineCount = text.split('\n').length;
    return Math.max(3, Math.min(20, lineCount)); // Minimum 3 rows, maximum 20 rows
  };

  const renderMarkdown = (content, markdownView, latexView) => {
    return (
      <div className='conversation-message-user-container'>
        <div className='text'>
          {markdownView ? (
            <MemoizedReactMarkdown
              key={latexView}
              remarkPlugins={
                latexView ? [remarkGfm, remarkBreaks, remarkMath] : [remarkGfm, remarkBreaks]
              }
              rehypePlugins={latexView ? [rehypeKatex] : []}
              components={{
                code({ inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline ? (
                    <CodeBlock
                      key={Math.random()}
                      language={(match && match[1]) || ''}
                      value={String(children).replace(/\n$/, '')}
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
                a: ({ href, children }) => {
                  const decodedHref = decodeURIComponent(href);
                  if (children?.length === 1 && children[0] === decodedHref) {
                    const childText = children[0];
                    const hasClosingBrace = childText.endsWith('}}');
                    const cleanText = hasClosingBrace ? childText.slice(0, -2) : childText;
                    return (
                      <>
                        <a href={encodeURI(cleanText)} target='_blank' rel='noopener noreferrer'>{cleanText}</a>
                        {hasClosingBrace && '}}'}
                      </>
                    );
                  }
                  // Default handling for all other cases
                  return <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>;
                },
              }}>
              {latexView
                ? prepareLatexContent(
                    shouldCollapse && !isExpanded
                      ? truncateContent(content, messageThreshold)
                      : content,
                  )
                : shouldCollapse && !isExpanded
                ? truncateContent(content, messageThreshold)
                : content}
            </MemoizedReactMarkdown>
          ) : (
            <p>
              {shouldCollapse && !isExpanded ? truncateContent(content, messageThreshold) : content}
            </p>
          )}
        </div>
        {shouldCollapse && (
          <Button
            onClick={toggleExpand}
            kind='ghost'
            size='sm'
            className='show-more-less-button'
            renderIcon={isExpanded ? ChevronUp : ChevronDown}>
            {isExpanded ? t('Show less') : t('Show more')}
          </Button>
        )}
        {message.upload_files && (
          <UploadedFileNameList
            uploadedFiles={message.upload_files}
            showLabel={!isEmpty(content)}
          />
        )}
      </div>
    );
  };

  const userMessage = () => {
    if (message.schema) {
      try {
        const content = JSON.parse(message.content);
        return (
          <ConversationMessageUserTool schema={message.schema} data={content} id={message.id} />
        );
      } catch (_) {
        return renderMarkdown(message.content, userMarkdownView, userLatexView);
      }
    } else {
      if (isEditing) {
        return (
          <TextArea
            id={`edit-message-${message.id}`}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            labelText={t('Edit message')}
            placeholder={t('Edit message')}
            className='edit-textarea'
            rows={calculateRows(editedContent)}
          />
        );
      }
      return (
        <div className='user-message-content'>
          {renderMarkdown(message.content, userMarkdownView, userLatexView)}
        </div>
      );
    }
  };

  const handleDeleteMessage = () => {
    setIsDeleting(true);
    deleteMessage.mutate(
      { messageId: message.id },
      {
        onError: () => {
          setIsDeleting(false);
          toast.error(t('Something went wrong with deleting message. Try again.'));
        },
      },
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(message.content);

  const handleEditMessage = () => {
    setIsEditing(true);
    setEditedContent(message.content);
  };

  const handleSaveEdit = () => {
    onEditMessage(message.id, editedContent);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedContent(message.content);
    setIsEditing(false);
  };

  const handleRegenerateClick = () => {
    onEditAndRegenerate(message.id, editedContent);
    setIsEditing(false);
  };

  return message.type === USER_MESSAGE ? (
    !message.sent_by_routine || isShown ? (
      <li
        className={`message user-message ${isSnapshotMessage ? 'snapshot-message' : ''} ${
          shouldCollapse || message.schema ? 'collapsible' : ''
        }`}
        data-cy='conversation-message'>
        <div className='message-wrapper'>
          <header className='message-header'>
            <div className='header-content'>
              {sender.image_url ? (
                <img src={getAbsoluteURL(sender.image_url)} alt={sender.name} className='avatar' />
              ) : (
                <div
                  className='avatar'
                  style={{
                    backgroundColor: generateSenderIconBgColor(sender),
                  }}>
                  {senderAvatarText}
                </div>
              )}
              <div className='message-info'>
                <span className='message-info__sender-name'>{sender.name}</span>
                {message.timestamp && (
                  <>
                    <span className='message-info__time'>
                      {displayMessageTime(message.timestamp, i18n.language)}
                      <span className='sent-by-routine'>
                        {message.sent_by_routine && '(Sent by routine) '}
                      </span>
                      {message.edited_at && (
                        <Tooltip
                          className='edited-tooltip'
                          align='bottom'
                          label={t('{{time}}', {
                            time:
                              displayMessageTime(message.edited_at, i18n.language)
                                .charAt(0)
                                .toUpperCase() +
                              displayMessageTime(message.edited_at, i18n.language).slice(1),
                          })}>
                          <span className='edited-indicator'>{'(edited)'}</span>
                        </Tooltip>
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className='actions'>
              {!isEditing && (
                <Button
                  as='block'
                  className='better-format-link view-mode-button'
                  hasIconOnly
                  renderIcon={ViewModeIcon}
                  iconDescription={t('Change view')}
                  tooltipPosition='bottom'
                  kind='ghost'
                  onClick={() => {
                    onBetterFormatClick();
                  }}
                />
              )}
              {(isMessageOwner || isConversationOwner) && (
                <>
                  {isEditing && (
                    <>
                      <Button
                        as='block'
                        onClick={handleCancelEdit}
                        className='cancel-button'
                        hasIconOnly
                        renderIcon={Close}
                        iconDescription={t('Cancel edit')}
                        tooltipPosition='bottom'
                        kind='ghost'
                      />
                      <Button
                        as='block'
                        onClick={handleSaveEdit}
                        className='save-button'
                        hasIconOnly
                        renderIcon={Checkmark}
                        iconDescription={t('Save edit')}
                        tooltipPosition='bottom'
                        kind='ghost'
                      />
                      {shouldShowRegenerateButton(message.id) && (
                        <Button
                          as='block'
                          onClick={handleRegenerateClick}
                          className='regenerate-button'
                          hasIconOnly
                          renderIcon={Renew}
                          iconDescription={t('Save & Regenerate')}
                          tooltipPosition='bottom'
                          kind='ghost'
                        />
                      )}
                    </>
                  )}
                  {!isEditing && (
                    <>
                      <ModalStateManager
                        renderLauncher={({ setOpen }) => (
                          <Button
                            as='block'
                            onClick={() => setOpen(true)}
                            className='delete-button'
                            hasIconOnly
                            renderIcon={TrashCan}
                            disabled={isDeleting || isDeletionDisabled}
                            iconDescription={t('Delete message')}
                            tooltipPosition='bottom'
                            kind='ghost'
                          />
                        )}
                        renderContent={({ open, setOpen }) => {
                          return (
                            <DeleteMessageModal
                              open={open}
                              setOpen={setOpen}
                              handleDeleteMessage={handleDeleteMessage}
                            />
                          );
                        }}
                      />
                      {!message.schema && (
                        <Button
                          as='block'
                          onClick={handleEditMessage}
                          className='edit-button'
                          hasIconOnly
                          renderIcon={Edit}
                          iconDescription={t('Edit message')}
                          tooltipPosition='bottom'
                          kind='ghost'
                          disabled={isEditing}
                        />
                      )}
                    </>
                  )}
                </>
              )}
              {!isEditing && (
                <Button
                  as='block'
                  onClick={() => handleCopyToClipboard(formatToolContent(message))}
                  className='copy-button'
                  hasIconOnly
                  renderIcon={isCopied ? Checkmark : CopyFile}
                  iconDescription={t('Copy text')}
                  tooltipPosition='bottom'
                  kind='ghost'
                />
              )}
            </div>
          </header>

          <div className='content'>{userMessage()}</div>
        </div>
      </li>
    ) : null
  ) : (
    <li
      className={`message assistant-message ${isSnapshotMessage ? 'snapshot-message' : ''}`}
      data-cy='conversation-message'>
      <div className='message-wrapper'>
        <ConversationMessageAssistant
          message={message}
          streamingResponse={streamingResponse}
          isError={isError}
          snapshot_llms={snapshot_llm_list}
          onRegenerate={onRegenerate}
          shouldShowRegenerateButton={shouldShowRegenerateButton}
          onEditMessage={onEditMessage}
          isConversationOwner={isConversationOwner}
          previousMessage={previousMessage}
          currentUser={currentUser}
          markdownView={assistantMarkdownView}
          latexView={assistantLatexView}
          onBetterFormatClick={onBetterFormatClick}
        />
      </div>
    </li>
  );
};

ConversationMessage.propTypes = {
  message: PropTypes.object.isRequired,
  streamingResponse: PropTypes.string,
  isError: PropTypes.bool,
  isConversationOwner: PropTypes.bool,
  isDeletionDisabled: PropTypes.bool,
  isShown: PropTypes.bool,
  snapshot_llms: PropTypes.array,
  onRegenerate: PropTypes.func.isRequired,
  onEditAndRegenerate: PropTypes.func.isRequired, // New prop
  shouldShowRegenerateButton: PropTypes.func.isRequired,
  onEditMessage: PropTypes.func.isRequired,
  previousMessage: PropTypes.object,
  currentUser: PropTypes.number.isRequired,
  userMarkdownView: PropTypes.bool.isRequired,
  userLatexView: PropTypes.bool.isRequired,
  assistantMarkdownView: PropTypes.bool.isRequired,
  assistantLatexView: PropTypes.bool.isRequired,
  onBetterFormatClick: PropTypes.func.isRequired,
};
