import { Modal, Toggle } from '@carbon/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ChangeViewModeModal = ({
  open,
  markdownView,
  toggleMarkdownView,
  latexView,
  toggleLatexView,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onRequestClose={onClose}
      modalHeading={t('View modes')}
      primaryButtonText={t('Save')}
      secondaryButtonText={t('Cancel')}
      onRequestSubmit={onSave}
      onSecondarySubmit={onClose}>
      <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}>
        <Toggle
          id='markdown-toggle'
          labelText={t('Markdown View')}
          toggled={markdownView}
          onToggle={toggleMarkdownView}
        />
        <Toggle
          id='latex-toggle'
          labelText={t('LaTeX View')}
          toggled={latexView}
          onToggle={toggleLatexView}
          disabled={!markdownView}
        />
        {/* <Checkbox
          id='set-as-default'
          labelText={t('Set as default')}
          checked={setAsDefault}
          onChange={() => setSetAsDefault(!setAsDefault)}
        /> */}
      </div>
    </Modal>
  );
};

ChangeViewModeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  markdownView: PropTypes.bool.isRequired,
  toggleMarkdownView: PropTypes.func.isRequired,
  latexView: PropTypes.bool.isRequired,
  toggleLatexView: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeViewModeModal;
