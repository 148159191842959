import { Dropdown } from '@carbon/react';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from 'utils/utils';

import supportedLanguages from '../../supported-languages.json';
import './LanguageSelector.scss';

const LanguageSelector = ({ direction = 'left' }) => {
  const { i18n } = useTranslation();

  const onChangeLanguage = (selectedLanguage) => {
    if (selectedLanguage !== i18n.language) {
      i18n.changeLanguage(selectedLanguage);
    }
  };
  const langueDropdownItems = reduce(
    supportedLanguages,
    (res, value, key) => {
      res.push({ id: key, ...value });
      return res;
    },
    [],
  );
  const selectedLanguage = find(langueDropdownItems, { id: i18n.language }) || {
    countryFlagCode: 'us',
    id: 'en',
    name: 'English',
  };

  return (
    <Dropdown
      titleText=''
      id='language-selector'
      label=''
      type='inline'
      size='lg'
      className={classNames(
        'language-selector',
        direction === 'left' ? 'language-selector--left' : 'language-selector--right',
      )}
      initialSelectedItem={selectedLanguage}
      items={langueDropdownItems}
      itemToString={(item) => (item ? item.nameLocal : '')}
      onChange={(e) => {
        onChangeLanguage(e.selectedItem.id);
      }}
    />
  );
};

LanguageSelector.propTypes = {
  direction: PropTypes.string,
};

export default LanguageSelector;
