import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import {
  API_PUBLIC_SNAPSHOT,
  API_CREATE_SNAPSHOT,
  API_FETCH_CONVERSATION_SNAPSHOTS,
  API_MANAGE_SNAPSHOT,
  API_CONTINUE_SNAPSHOT,
  API_SNAPSHOT_SIGNUP,
} from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

export const usePublicSnapshot = (uuid) => {
  return useQuery({
    queryKey: ['publicSnapshot', uuid],
    queryFn: async () => {
      const url = buildUrl(API_PUBLIC_SNAPSHOT, [{ name: 'uuid', value: uuid }]);
      const res = await fetch(url);
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    initialData: {
      conversation_data: {
        messages: [],
        llm_models: [],
      },
    },
  });
};

export const useFetchSnapshots = (conversation_id) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: ['snapshots', conversation_id],
    queryFn: async () => {
      const url = buildUrl(API_FETCH_CONVERSATION_SNAPSHOTS, [
        { name: 'conversation_id', value: conversation_id },
      ]);
      const res = await apiClient(url);
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
  });
};

export const useCreateSnapshot = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ conversation_id, anonymize }) => {
      const url = buildUrl(API_CREATE_SNAPSHOT, [
        { name: 'conversation_id', value: conversation_id },
      ]);
      const res = await apiClient(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ anonymize: anonymize.toString() }),
      });
      if (!res.ok) throw Error('Creation failed');
      return res.json();
    },
    {
      onSuccess: (_, { conversation_id }) => {
        queryClient.invalidateQueries(['snapshots', conversation_id]);
      },
    },
  );
};

export const usePatchSnapshot = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ snapshot_id, is_public }) => {
      const url = buildUrl(API_MANAGE_SNAPSHOT, [{ name: 'snapshot_id', value: snapshot_id }]);
      const res = await apiClient(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_public: is_public.toString() }),
      });
      if (!res.ok) throw Error('Update failed');
      return res.json();
    },
    {
      onSuccess: (_, { conversation_id }) => {
        queryClient.invalidateQueries(['snapshots', conversation_id]);
      },
    },
  );
};

export const useDeleteSnapshot = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ snapshot_id }) => {
      const url = buildUrl(API_MANAGE_SNAPSHOT, [{ name: 'snapshot_id', value: snapshot_id }]);
      const res = await apiClient(url, {
        method: 'DELETE',
      });
      if (!res.ok) throw Error('Deletion failed');
      return null;
    },
    {
      onSuccess: (_, { conversation_id }) => {
        queryClient.invalidateQueries(['snapshots', conversation_id]);
      },
    },
  );
};

export const useContinueSnapshotConversation = () => {
  const apiClient = useContext(ApiClientContext);

  return useMutation(async ({ uuid }) => {
    const url = buildUrl(API_CONTINUE_SNAPSHOT, [{ name: 'uuid', value: uuid }]);
    const res = await apiClient(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res.ok) {
      const errorResponse = await res.json();
      throw new Error(errorResponse.error.message);
    }
    return res.json();
  });
};

export const useSnapshotSignupMutation = () => {
  return useMutation(async (data) => {
    const url = buildUrl(API_SNAPSHOT_SIGNUP);
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return res.json();
  });
};
