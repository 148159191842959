import { WarningFilled } from '@carbon/icons-react';
import { Modal, Theme } from '@carbon/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DeleteMessageModal = ({ open, setOpen, handleDeleteMessage }) => {
  const { t } = useTranslation();

  return (
    <Theme theme='g100' id='collaboration-modal'>
      <Modal
        open={open}
        onRequestClose={() => setOpen(false)}
        onRequestSubmit={() => {
          handleDeleteMessage();
          setOpen(false);
        }}
        danger
        modalHeading={
          <span className='modal-header'>
            <WarningFilled size={20} color='#F1C21B' />
            {t('Are you sure you want to delete the message?')}
          </span>
        }
        primaryButtonText={t('Yes, delete them')}
        secondaryButtonText={t('Cancel')}>
        <div className='modal-body'>{t('This message and its response will be deleted.')}</div>
      </Modal>
    </Theme>
  );
};

DeleteMessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleDeleteMessage: PropTypes.func.isRequired,
};

export default DeleteMessageModal;
