import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isNil, omitBy, debounce } from 'lodash';
import get from 'lodash/get';
import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  API_DELETE_CONVERSATION,
  API_DELETE_MESSAGE,
  API_DETAIL_CONVERSATION,
  API_DOWNLOAD_CONVERSATION,
  API_EDIT_MESSAGE,
  API_FETCH_CONVERSATION_MESSAGES,
  API_FETCH_CONVERSATIONS,
  API_SEND_MESSAGE,
  API_STOP_MESSAGE,
  API_STREAM_MESSAGE,
  WS_MESSAGE_TYPES,
} from 'constants/constants';
import { SORT_FIELDS, SORT_ORDERS } from 'constants/sortConstants';
import { ApiClientContext } from 'context/api-client';
import { WebSocketContext } from 'context/websocket';
import { buildUrl } from 'utils/utils';

export const conversationKeys = {
  all: ['conversations'],
  details: () => [...conversationKeys.all, 'detail'],
  detail: (id) => [...conversationKeys.details(), id],
  messages: (id, fromMessageId, limit) => [...conversationKeys.details(id), fromMessageId, limit],
  lists: () => [...conversationKeys.all, 'list'],
  list: (filter) => [...conversationKeys.lists(), filter],
};

export const unreadMessagesKeys = {
  all: ['unreadMessages'],
  details: () => [...unreadMessagesKeys.all, 'unread'],
  detail: (id) => [...unreadMessagesKeys.details(), id],
};

export const useConversationsQuery = ({
  sortParams = '',
  searchQuery = '',
  isEnabled = true,
  refetchInterval = false,
  conversationId = '',
}) => {
  // Only sort in Descending order for sort by similarity
  if (sortParams == `sort_by=${SORT_FIELDS.SIMILARITY}&sort_order=${SORT_ORDERS.ASC}`) {
    sortParams = `sort_by=${SORT_FIELDS.SIMILARITY}&sort_order=${SORT_ORDERS.DESC}`;
  }

  const apiClient = useContext(ApiClientContext);
  const { lastMessage: newMessageWs } = useContext(WebSocketContext);

  const queryClient = useQueryClient({
    staleTime: Infinity,
  });
  const queryKey = useMemo(
    () =>
      conversationKeys.list({
        sortParams,
        searchQuery,
      }),
    [sortParams, searchQuery],
  );

  // Handle debouncing refetch
  const debouncedRefetch = useCallback(
    debounce(() => {
      queryClient.invalidateQueries({ queryKey: conversationKeys.lists() });
    }, 400),
    [queryClient],
  );
  useEffect(() => {
    return () => {
      debouncedRefetch.cancel();
    };
  }, [debouncedRefetch]);

  const fetchConversations = async ({ pageParam = 1 }) => {
    const params = new URLSearchParams(sortParams);

    if (searchQuery) {
      params.append('search', searchQuery);
    }

    if (pageParam) {
      params.append('page', pageParam);
    }

    if (conversationId) {
      params.append('conversation_id', conversationId);
    }

    const fetch_url = `${buildUrl(API_FETCH_CONVERSATIONS)}?${params.toString()}`;

    const res = await apiClient(fetch_url);
    if (!res.ok) throw Error('Load failed');
    return res.json();
  };

  const handleUpdateUnreadCount = async (
    conversationId,
    unreadCount,
    hasUnreadMentions = false,
  ) => {
    let updated = false;
    queryClient.setQueryData({ queryKey }, (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          results: page.results.map((conversation) => {
            if (conversation.id !== conversationId) {
              return conversation;
            }
            if (unreadCount === -1 && conversation.num_of_unread_messages === 0) {
              return conversation;
            }

            updated = true;
            const newUnreadCount =
              unreadCount === -1 ? 0 : (conversation.num_of_unread_messages || 0) + unreadCount;
            const newHasUnreadMentions =
              unreadCount === -1 ? false : conversation.has_unread_mentions || hasUnreadMentions;

            return {
              ...conversation,
              num_of_unread_messages: newUnreadCount,
              has_unread_mentions: newHasUnreadMentions,
            };
          }),
        })),
      };
    });

    if (updated) {
      await queryClient.cancelQueries({ queryKey: conversationKeys.lists() });
      debouncedRefetch();
    }

    return updated;
  };
  const handleMessageEvent = async (message) => {
    const data = JSON.parse(message.data);
    if (!data) return;
    if (data.type === WS_MESSAGE_TYPES.UNREAD_COUNT_UPDATE) {
      const {
        conversation_id: conversationId,
        unread_count: unreadCount,
        has_unread_mentions: hasUnreadMentions,
      } = data.data;
      await handleUpdateUnreadCount(conversationId, unreadCount, hasUnreadMentions);
    }
  };

  useEffect(() => {
    if (newMessageWs) {
      handleMessageEvent(newMessageWs);
    }
  }, [newMessageWs]);

  return useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) => fetchConversations({ pageParam }),
    enabled: isEnabled,
    initialPageParam: 1,
    refetchInterval,
    getPreviousPageParam: (firstPage) =>
      firstPage.previous ? new URL(firstPage.previous).searchParams.get('page') : undefined,
    getNextPageParam: (lastPage) =>
      lastPage.next ? new URL(lastPage.next).searchParams.get('page') : undefined,
  });
};

export const useConversationDetails = (id, isRoutine = false) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: conversationKeys.detail(id),
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_DETAIL_CONVERSATION, [{ name: 'id', value: id }]));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    initialData: {
      data: {
        participants: [],
        dlp_preference: null,
      },
    },
    enabled: !isRoutine && !!id && id !== 'new',
  });
};

export const useSendMessage = () => {
  const apiClient = useContext(ApiClientContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      toolId,
      modelId,
      conversationId,
      mentionedUserIds,
      regenerateMessageId,
      content,
      jsonToolData,
      nonAI = false,
      files = [],
    }) => {
      const formData = new FormData();

      // Create an object with all potential fields
      const fields = omitBy(
        {
          message: content,
          tool_id: toolId,
          model_id: modelId,
          conversation_id: conversationId !== 0 ? conversationId : undefined,
          regenerate_message_id: regenerateMessageId,
          non_ai: nonAI,
        },
        isNil,
      );

      // Append all non-nil fields to formData
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (!isEmpty(mentionedUserIds)) {
        mentionedUserIds.forEach((userId) => {
          formData.append('mentioned_user_ids', userId);
        });
      }

      // Add files
      if (!isEmpty(files)) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (!isEmpty(jsonToolData)) {
        formData.append('json_tool_data', JSON.stringify(jsonToolData));
      }

      const res = await apiClient(buildUrl(API_SEND_MESSAGE), {
        method: 'POST',
        body: formData,
      });

      if (!res.ok) {
        if (res.status === 400) {
          const resBody = await res.json();
          const error = get(resBody, ['error', 'message']);
          if (error === 'Token limit exceeded')
            throw Error(
              t(
                'Your message will exceed the token limit for this conversation. Please shorten your message or try using Rememberizer for knowledge source for big files.',
              ),
            );
          else throw Error(error);
        }
        throw Error(t('Something went wrong. Unable to send message.'));
      }

      const resBody = await res.json();
      const resConversationId = get(resBody, ['data', 'conversation_id']);
      const rememberizer_api_query = get(resBody, ['data', 'rememberizer_api_query'], {});
      const rememberizerApiQueryArguments = rememberizer_api_query.arguments;

      if (rememberizerApiQueryArguments) {
        const formattedArguments = Object.entries(rememberizerApiQueryArguments).map(
          ([key, value], index) => (
            <React.Fragment key={key}>
              <span>
                {key}: {value}
              </span>
              {index < Object.keys(rememberizerApiQueryArguments).length - 1 && <br />}
            </React.Fragment>
          ),
        );

        if (!isEmpty(rememberizer_api_query)) {
          toast.success(
            <div>
              {t('SkyDeck.ai sent this info to ') +
                rememberizer_api_query.endpoint.replace(/^https?:\/\//, '')}
              <br />
              {formattedArguments}
            </div>,
            { position: toast.POSITION.TOP_RIGHT, className: 'rememberizer-toast' },
          );
        }
      }

      if (!resConversationId) {
        throw Error(t('Something went wrong. Unable to get conversation id.'));
      }
      queryClient.invalidateQueries({ queryKey: conversationKeys.lists() });
      return resBody;
    },
  );
};

export const useConversationMessages = (conversationId, fromMessageId, limit = 10) => {
  const apiClient = useContext(ApiClientContext);
  return useQuery({
    queryKey: conversationKeys.messages(conversationId, fromMessageId, limit),
    queryFn: async () => {
      const res = await apiClient(
        buildUrl(API_FETCH_CONVERSATION_MESSAGES, [
          { name: 'id', value: conversationId },
          { name: 'fromMessageId', value: fromMessageId },
          { name: 'limit', value: limit },
        ]),
      );
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    enabled: false,
    cacheTime: 0,
  });
};

export const useDeleteConversation = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ conversationId }) => {
      const res = await apiClient(
        buildUrl(API_DELETE_CONVERSATION, [{ name: 'id', value: conversationId }]),
        {
          method: 'DELETE',
        },
      );
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: conversationKeys.lists(),
      });

      // Get snapshot of previous data for all matching queries
      const previousQueries = queryClient.getQueriesData({
        queryKey: conversationKeys.lists(),
      });

      // Optimistically update all lists queries
      previousQueries.forEach(([queryKey]) => {
        queryClient.setQueryData(queryKey, (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              results: page.results.filter(
                (conversation) => conversation.id !== variables.conversationId,
              ),
            })),
          };
        });
      });

      // Return snapshot for rollback
      return { previousQueries };
    },
    onError: (_error, _variables, context) => {
      // Rollback on error
      if (context?.previousQueries) {
        context.previousQueries.forEach(([queryKey, previousData]) => {
          queryClient.setQueryData(queryKey, previousData);
        });
      }
    },
    onSettled: () => {
      // Refetch after error or success
      queryClient.invalidateQueries({
        queryKey: conversationKeys.lists(),
      });
    },
  });
};

export const useDeleteMessage = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ messageId }) => {
      const res = await apiClient(
        buildUrl(API_DELETE_MESSAGE, [{ name: 'messageId', value: messageId }]),
        {
          method: 'DELETE',
        },
      );
      if (!res.ok) {
        throw Error('Delete failed');
      }
      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: conversationKeys.lists() });
      },
    },
  );
};

export const usePatchConversation = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ conversationId, name, dlpPreference }) => {
      const body = JSON.stringify({
        ...(name && { name }),
        ...(dlpPreference && { dlp_preference: dlpPreference }),
      });
      const res = await apiClient(
        buildUrl(API_DETAIL_CONVERSATION, [{ name: 'id', value: conversationId }]),
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        },
      );
      if (!res.ok) throw Error(res.statusText);
      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: conversationKeys.lists() });
        queryClient.invalidateQueries({ queryKey: conversationKeys.details() });
      },
    },
  );
};

export const useDlpPreference = (id, isRoutine) => {
  const [dlpPreference, setDlpPreference] = useState();
  const conversationDetailsQuery = useConversationDetails(id, isRoutine);
  const existingDlpPreference = useMemo(
    () => get(conversationDetailsQuery, 'data.data.dlp_preference'),
    [conversationDetailsQuery?.data],
  );

  useEffect(() => {
    if (existingDlpPreference) {
      setDlpPreference(existingDlpPreference);
    }
  }, [existingDlpPreference]);

  return {
    dlpPreference,
    setDlpPreference,
    existingDlpPreference,
  };
};

export const useStreamingMessage = (message) => {
  const MAX_RETRY_TIMES = 5;
  const DELAY_TIME = 1000;
  const apiClient = useContext(ApiClientContext);

  const currentMessage = useRef('');
  const abortController = useRef();

  const sendStopStreamingMessage = async () => {
    const body = JSON.stringify({
      messageContent: currentMessage.current,
    });
    const res = await apiClient(
      buildUrl(API_STOP_MESSAGE, [{ name: 'messageId', value: message.id }]),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      },
    );
    if (!res.ok) throw Error('Load failed');
    return res.json();
  };

  const cleanUp = () => {
    if (abortController.current) {
      abortController.current.abort();
      currentMessage.current = '';
    }
  };

  const stopGenerating = async (notSendStopStreamingMessage = false) => {
    cleanUp();
    if (notSendStopStreamingMessage) return;
    await sendStopStreamingMessage();
  };

  const stream = (requestData, callbacks = {}) => {
    const { onSettle, updateMessage, handleError } = callbacks;
    if (abortController.current) {
      abortController.current.abort();
    }
    abortController.current = new AbortController();
    const { signal } = abortController.current;

    const messageId = message.id;
    const isSmartTool = message.is_smart_tool;
    let buffer = '';

    const tryStream = async () => {
      try {
        const res = await apiClient(buildUrl(API_STREAM_MESSAGE), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (!res.ok) {
          return handleError(res);
        }

        const reader = res.body.getReader();
        const decoder = new TextDecoder();
        currentMessage.current = '';
        let done = false;
        while (!done) {
          if (signal.aborted) {
            reader.cancel();
            break;
          }
          const { value, done: streamDone } = await reader.read();

          done = streamDone;
          if (value) {
            const decodedChunk = decoder.decode(value, { stream: !done });
            updateMessage(decodedChunk);
            buffer = buffer + decodedChunk;
            currentMessage.current = isSmartTool ? decodedChunk : buffer;
          }
        }
        onSettle({ messageId: messageId, content: currentMessage.current });
      } catch (error) {
        throw Error('Fail to fetch ', error);
      }
    };

    return new Promise((resolve, reject) => {
      let attemptCount = 0;
      const attempt = () => {
        if (signal.aborted) {
          return;
        }
        tryStream()
          .then(resolve)
          .catch((error) => {
            if (attemptCount < MAX_RETRY_TIMES) {
              attemptCount++;
              console.log(`Attempt ${attemptCount} failed. Retrying in ${DELAY_TIME}ms...`);
              setTimeout(attempt, DELAY_TIME);
            } else reject(error);
          });
      };
      attempt();
    });
  };

  return { stream, stopGenerating, cleanUp };
};

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (data) => {
    navigator.clipboard.writeText(data).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    });
  };

  return { isCopied, handleCopyToClipboard };
};

export const useDownloadConversation = () => {
  const apiClient = useContext(ApiClientContext);

  return useMutation({
    mutationFn: async (conversationId) => {
      const res = await apiClient(
        buildUrl(API_DOWNLOAD_CONVERSATION, [{ name: 'conversationId', value: conversationId }]),
      );

      if (!res.ok) {
        throw res;
      }

      const blob = await res.blob();
      return URL.createObjectURL(blob);
    },
  });
};

export const useEditMessage = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async ({ conversationId, messageId, content }) => {
      try {
        const res = await apiClient(
          buildUrl(API_EDIT_MESSAGE, [
            { name: 'conversationId', value: conversationId },
            { name: 'messageId', value: messageId },
          ]),
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content }),
          },
        );

        if (!res.ok) {
          const errorData = await res.json();
          throw new Error(
            errorData.error?.message || t('Something went wrong. Unable to edit message.'),
          );
        }

        const updatedMessage = await res.json();
        queryClient.invalidateQueries({ queryKey: conversationKeys.lists() });
        return updatedMessage;
      } catch (error) {
        console.error('Error editing message:', error);
        throw error;
      }
    },
  });
};
