import PropTypes from 'prop-types';
import React, { createContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { API_REFRESH_TOKEN } from 'constants/constants';
import { PATH_SIGN_IN } from 'constants/routes';
import { buildUrl, getSubdomain } from 'utils/utils';

export const ApiClientContext = createContext();

async function refreshToken() {
  const response = await fetch(buildUrl(API_REFRESH_TOKEN), {
    method: 'POST',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to refresh token');
  }
}

export const ApiClientProvider = ({ children }) => {
  const subdomain = getSubdomain();

  if (subdomain === 'share') {
    const emptyClient = () => {};
    return <ApiClientContext.Provider value={emptyClient}>{children}</ApiClientContext.Provider>;
  }

  const { i18n } = useTranslation();

  const apiClient = useCallback(async (url, options) => {
    const optionsObject = {
      ...options,
      credentials: 'include',
      headers: {
        ...(options?.headers ?? {}),
        'Accept-Language': i18n.language.replace('_', '-'),
      },
    };

    let response = await fetch(url, optionsObject);
    if (response.status === 401) {
      const originalRequest = { ...optionsObject };

      try {
        await refreshToken();
        response = await fetch(url, originalRequest);
      } catch (error) {
        const currentPath = window.location.pathname;

        const signInUrl = new URL(PATH_SIGN_IN, window.location.origin);
        signInUrl.searchParams.append('next', currentPath);
        window.location.replace(signInUrl.toString());

        return { ok: false };
      }
    }

    return response;
  }, []);

  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>;
};

ApiClientProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
