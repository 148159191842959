import { PropTypes } from 'prop-types';
import React, { createContext } from 'react';

import { useFeatures } from 'hooks/features';

export const FeaturesContext = createContext();

export const FeaturesProvider = ({ children }) => {
  const features = useFeatures();
  const data = features.isError ? { data: [] } : features.data;

  return <FeaturesContext.Provider value={data}>{children}</FeaturesContext.Provider>;
};

FeaturesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

