import { WatsonHealthRotate_360 } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import skydeckLogo from 'assets/skydeck_logo_with_orange_text_vertical.png';
import GeneralError from 'components_v3/GeneralError';
import Loader from 'components_v3/Loader';
import { useSubdomainInfo } from 'hooks/account';
import { isUserSignInPage } from 'utils/utils';

import './FusionAuthWrapper.scss';

const CheckSubdomainWrapper = ({ children }) => {
  const { t } = useTranslation();

  const subdomain = window.location.hostname.split('.')[0];
  if (subdomain === 'share' || isUserSignInPage()) {
    return children;
  }

  const subdomainInfo = useSubdomainInfo(subdomain);

  if (subdomainInfo.isLoading) {
    return <Loader />;
  }

  if (subdomainInfo.isError) {
    return (
      <GeneralError
        className='fusionauth-error'
        header={<img src={skydeckLogo} alt={t('SkyDeck logo')} className='logo' />}
        errorMessage={t('Unable to obtain information regarding your GenStudio application.')}
        actions={
          <Button
            data-cy='retry-button'
            onClick={subdomainInfo?.refetch}
            className='retry-button'
            renderIcon={WatsonHealthRotate_360}
          >
            {t('Retry')}
          </Button>
        }
      />
    );
  }

  return children;
};

CheckSubdomainWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CheckSubdomainWrapper;
