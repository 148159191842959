// src/constants/variableConstants.js

export const VARIABLE_TYPES = {
  TEXT: 'text',
  URL: 'url',
  SINGLE_SELECT: 'single-select',
  MULTI_SELECT: 'multi-select',
};

export const VARIABLE_TYPE_OPTIONS = Object.values(VARIABLE_TYPES);

export const NavItem = Object.freeze({
  Conversations: 0,
  Routines: 1,
});
