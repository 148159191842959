import { Button, Theme } from '@carbon/react';
import { useFusionAuth } from '@fusionauth/react-sdk';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import './RapidOnboarding.scss';

import AppLogo from 'assets/v3/app_logo.png';
import { API_LOGIN_CALLBACK } from 'constants/constants';
import { HOME_PATH } from 'constants/routes';
import { buildUrl, useBypassFA } from 'utils/utils';

import Loader from './Loader';

const INVALID_TOKEN = 'Invalid Token';

export const RapidOnboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [errorMessage, setErrorMessage] = useState('');

  const { t } = useTranslation();
  const { isAuthenticated } = useFusionAuth();

  useEffect(() => {
    const checkTokenValidity = async () => {
      if (!useBypassFA()) {
        try {
          window.location.assign(buildUrl(API_LOGIN_CALLBACK) + '?token=' + token);
        } catch (error) {
          setErrorMessage(INVALID_TOKEN);
        }
      }
    };
    if (!useBypassFA() && isAuthenticated) {
      navigate('/', { replace: true });
      return;
    }
    checkTokenValidity();
  }, [token]);

  if (errorMessage === INVALID_TOKEN) {
    return (
      <Theme theme='g90' className='rapid-onboarding-error'>
        <div className='rapid-onboarding-error__wrapper'>
          <img src={AppLogo} alt={t('Logo')} className='mb-4 w-20 h-auto' />
          <h1 className='rapid-onboarding-error__title'>{t('Invalid or expired token.')}</h1>
          <Button onClick={() => navigate(HOME_PATH)} className='rapid-onboarding-error__button'>
            {t('Return to the homepage')}
          </Button>
        </div>
      </Theme>
    );
  }

  return <Loader />;
};
