import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  API_DELETE_TOOL,
  API_DOWNLOAD_PROMPTS,
  API_FETCH_MODELS,
  API_FETCH_TOOLS,
  API_FETCH_TOOL_DETAIL,
  API_UPLOAD_PROMPTS,
  API_UPDATE_TOOL,
  SIDEBAR_NONE_TOOL,
} from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

const toolKeys = {
  all: ['tools'],
  list: () => [...toolKeys.all, 'list'],
  details: () => [...toolKeys.all, 'detail'],
  detail: (id) => [...toolKeys.details(), id],
  models: ['models'],
};

export const useToolsQuery = (searchQuery, sortField, sortOrder) => {
  const apiClient = useContext(ApiClientContext);
  const { i18n } = useTranslation();

  const fetchTools = async ({ pageParam = 1, searchQuery, sortField, sortOrder }) => {
    const params = new URLSearchParams();

    // Only add parameters if they have a value
    if (pageParam) params.append('page', pageParam);
    if (searchQuery) params.append('search', searchQuery);
    if (sortField && sortOrder) {
      params.append('ordering', sortOrder === 'desc' ? `-${sortField}` : sortField);
    }

    const res = await apiClient(
      `${buildUrl(API_FETCH_TOOLS)}${params.toString() ? `?${params.toString()}` : ''}`,
    );
    if (!res.ok) throw Error('Load failed');
    return res.json();
  };

  return useInfiniteQuery({
    queryKey: [...toolKeys.list(), searchQuery, sortField, sortOrder, i18n.language],
    queryFn: async ({ pageParam = 1 }) =>
      fetchTools({ pageParam, searchQuery, sortField, sortOrder }),
    staleTime: 10 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    refetchInterval: 30 * 60 * 1000,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.next) {
        const url = new URL(lastPage.next);
        return url.searchParams.get('page');
      }
      return undefined;
    },
  });
};

export const useToolDetails = (id) => {
  const apiClient = useContext(ApiClientContext);
  const { i18n } = useTranslation();

  return useQuery({
    queryKey: [...toolKeys.detail(id), i18n.language],
    queryFn: async () => {
      if (id === SIDEBAR_NONE_TOOL) return { id };
      const res = await apiClient(buildUrl(API_FETCH_TOOL_DETAIL, [{ name: 'id', value: id }]));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
    enabled: !!id,
  });
};

export const prefetchToolDetails = (id, queryClient, apiClient) => {
  const { i18n } = useTranslation();

  return queryClient.prefetchQuery({
    queryKey: [...toolKeys.detail(id), i18n.language],
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_FETCH_TOOL_DETAIL, [{ name: 'id', value: id }]));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};

export const useUploadTools = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ selectedFiles }) => {
      var formData = new FormData();

      selectedFiles.forEach((file) => {
        formData.append('file', file, file.name);
      });

      const res = await apiClient(buildUrl(API_UPLOAD_PROMPTS), {
        method: 'POST',
        body: formData,
        redirect: 'follow',
      });
      if (!res.ok) {
        const error = new Error('Upload failed');
        try {
          error.data = await res.json();
        } catch (e) {
          console.log('Upload tools failed, response:', res);
        }
        throw error;
      }
      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(toolKeys.all);
      },
    },
  );
};

export const useDownloadTool = () => {
  const apiClient = useContext(ApiClientContext);

  return useMutation(async ({ id }) => {
    const res = await apiClient(buildUrl(API_DOWNLOAD_PROMPTS, [{ name: 'id', value: id }]));
    if (!res.ok) throw Error('Download failed');
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);

    const regExpFilename = /filename="(?<filename>.*)"/;
    const contentDispositionHeader = res.headers.get('Content-Disposition');
    const filename =
      regExpFilename.exec(contentDispositionHeader)?.groups?.filename ??
      url.substring(url.lastIndexOf('/') + 1);

    return { url, filename };
  });
};

export const useDeleteToolMutation = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }) => {
      const res = await apiClient(buildUrl(API_DELETE_TOOL, [{ name: 'id', value: id }]), {
        method: 'DELETE',
      });
      if (!res.ok) throw Error('Delete failed');
      return res.json();
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.removeQueries({ queryKey: toolKeys.detail(variables.id), exact: true });
        queryClient.invalidateQueries(toolKeys.list());
      },
    },
  );
};

export const useNoneToolModels = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: toolKeys.models,
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_FETCH_MODELS));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    staleTime: 10 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};

export const useUpdateToolMutation = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, jsonToolData, images, documents, removeImages, removeDocuments }) => {
      const formData = new FormData();
      if (!isEmpty(jsonToolData)) {
        formData.append('json_tool_data', JSON.stringify(jsonToolData));
      }

      // Add images
      if (!isEmpty(images)) {
        images.forEach((file) => {
          formData.append('images', file);
        });
      }

      // Add documents
      if (!isEmpty(documents)) {
        documents.forEach((file) => {
          formData.append('documents', file);
        });
      }

      // Add remove images
      if (!isEmpty(removeImages)) {
        removeImages.forEach((image) => {
          formData.append('remove_images', image);
        });
      }

      // Add remove documents
      if (!isEmpty(removeDocuments)) {
        removeDocuments.forEach((document) => {
          formData.append('remove_documents', document);
        });
      }

      const res = await apiClient(buildUrl(API_UPDATE_TOOL, [{ name: 'id', value: id }]), {
        method: 'PATCH',
        body: formData,
      });
      if (!res.ok) {
        const errorData = await res.json();
        let errorMessage = errorData?.error?.message ?? 'Update failed';
        const error = new Error(errorMessage);
        error.status = res.status;
        error.statusText = res.statusText;
        throw error;
      }
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(toolKeys.detail(variables.id), data);
        queryClient.invalidateQueries(toolKeys.all);
      },
    },
  );
};

export const useCreateToolMutation = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const res = await apiClient(buildUrl(API_FETCH_TOOLS), {
        method: 'POST',
      });

      if (!res.ok) throw Error('Created failed');
      return res.json();
    },
    {
      onSuccess: (_data) => {
        queryClient.invalidateQueries(toolKeys.list());
      },
    },
  );
};
