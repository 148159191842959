import { UserAvatar } from '@carbon/icons-react';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import PopoverWrapper from 'components_v3/PopoverWrapper';
import { ACCOUNT_SETTINGS_PATH } from 'constants/routes';
import { useProfileQuery } from 'hooks/account';
import { useCCSessionMutation, useLogout } from 'hooks/authentication';
import { getAbsoluteURL } from 'utils/utils';
import './MenuProfile.scss';

export const MenuProfile = () => {
  const profileQuery = useProfileQuery();
  const { t } = useTranslation();
  const logout = useLogout();
  const CCSessionMutation = useCCSessionMutation();
  const navigate = useNavigate();

  const onSignOutClick = () => {
    logout();

    document.title = 'SkyDeck.ai GenStudio';
  };

  const onManageAccountClick = async () => {
    const res = await CCSessionMutation.mutateAsync();
    if (res.ok) {
      window.open(import.meta.env.VITE_CC_URL);
    } else {
      toast.error(t('You do not have managing permission!'));
    }
  };

  const onAccountSettingsClick = () => {
    navigate(ACCOUNT_SETTINGS_PATH);
  };

  return (
    <div className='account'>
      <OverflowMenu
        flipped
        renderIcon={() => (
          <PopoverWrapper content={<div className='account__tooltip'>{t('Account')}</div>}>
            <div className='avatar-container'>
              {profileQuery.data && profileQuery.data.data.image_url ? (
                <img
                  src={getAbsoluteURL(profileQuery.data.data.image_url)}
                  alt='User Avatar'
                  className='user-avatar'
                  width={20}
                  height={20}
                />
              ) : (
                <UserAvatar size={20} />
              )}
            </div>
          </PopoverWrapper>
        )}
        size='lg'
        data-floating-menu-container
        menuOptionsClass='profile-overflow-menu'
        focusTrap={false}>
        {profileQuery.data && (
          <PopoverWrapper
            content={<div className='account__tooltip'>{profileQuery.data.data.email}</div>}>
            <div id='profile-item-wrapper' className='cds--overflow-menu-options__btn'>
              <span id='profile-name-item' className='cds--overflow-menu-options__option-content'>
                {profileQuery.data.data.name}
              </span>
              <span id='profile-email-item' className='cds--overflow-menu-options__option-content'>
                {profileQuery.data.data.email}
              </span>
            </div>
          </PopoverWrapper>
        )}
        {profileQuery.data?.data?.is_owner_or_admin && (
          <OverflowMenuItem
            wrapperClassName='manage-account-btn-wrapper'
            itemText={t('Manage Account')}
            onClick={onManageAccountClick}
            requireTitle
          />
        )}

        <OverflowMenuItem
          hasDivider
          itemText={t('Account Settings')}
          onClick={onAccountSettingsClick}
          requireTitle
        />
        <OverflowMenuItem
          hasDivider
          itemText={t('Sign out')}
          onClick={onSignOutClick}
          requireTitle
        />
      </OverflowMenu>
    </div>
  );
};

export default MenuProfile;
