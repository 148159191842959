import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React from 'react';
import './RespondingIndicator.scss';
import { classNames } from 'utils/utils';

const container = {
  breath: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const item = {
  breath: {
    scale: 1.5,
    transition: {
      ease: 'linear',
      duration: 0.3,
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
};
export const RespondingIndicator = ({ centered }) => {
  return (
    <div
      className={classNames('responding-indicator', centered && 'responding-indicator--centered')}
    >
      <motion.div className='container' variants={container} animate='breath'>
        <motion.div className='dot' variants={item} />
        <motion.div className='dot' variants={item} />
        <motion.div className='dot' variants={item} />
      </motion.div>
    </div>
  );
};

RespondingIndicator.propTypes = {
  centered: PropTypes.bool,
};
