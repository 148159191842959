import { CopyFile, Checkmark } from '@carbon/icons-react';
import { Button, Theme } from '@carbon/react';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import './code-block.scss';

export const CodeBlock = memo(({ language, value }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return;
    }

    navigator.clipboard.writeText(value).then(() => {
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    });
  };

  return (
    <Theme theme='g100' className='code-block'>
      <div className='block-header'>
        <span className='language-name'>{language}</span>

        <div>
          <Button
            hasIconOnly
            renderIcon={isCopied ? Checkmark : CopyFile}
            onClick={copyToClipboard}
            iconDescription={isCopied ? t('Copied') : t('Copy code')}
            tooltipAlignment='start'
            size='sm'
            kind='ghost'
          />
        </div>
      </div>

      <SyntaxHighlighter language={language} style={oneDark} customStyle={{ margin: 5 }}>
        {value}
      </SyntaxHighlighter>
    </Theme>
  );
});

CodeBlock.displayName = 'CodeBlock';
CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
