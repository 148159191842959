import PropTypes from 'prop-types';
import React, { memo } from 'react';

const LogoComponent = ({ logoUrl, altText }) => (
  <a href={import.meta.env.VITE_CC_URL} target='_blank' rel='noreferrer'>
    <img className='company-logo' src={logoUrl} alt={altText} />
  </a>
);

const Logo = memo(LogoComponent);
Logo.displayName = 'Logo';

Logo.propTypes = {
  logoUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

LogoComponent.propTypes = {
  logoUrl: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

export { Logo };
