export const HOME_PATH = '/';
export const CONVERSATION_LIST_PATH = '/conversations';
export const CONVERSATION_PATH = '/conversations/:conversationId';
export const RAPID_ONBOARDING_PATH = '/rapid-onboarding';
export const COLLABORATED_CONVERSATION = '/conversation/:conversation_slug';
export const buildConversationUrl = (conversationId) =>
  CONVERSATION_PATH.replace(':conversationId', conversationId);
export const PATH_AUTH_ERROR = '/auth-error';
export const PATH_SIGN_IN = '/sign-in';
export const PASSWORD_CHANGE_PATH = '/password/change';
export const FORGOT_PASSWORD_PATH = '/password/forgot';
export const PASSWORD_SENT_PATH = '/password/sent';

export const ROUTINE_LIST_PATH = '/routines';
export const ROUTINE_PATH = '/routines/:conversationId';
export const COLLABORATED_ROUTINE = '/routine/:routine_slug';
export const buildRoutineUrl = (conversationId) =>
  ROUTINE_PATH.replace(':conversationId', conversationId);
export const SNAPSHOT_PATH = '/c/:snapshot_uuid';
export const CONTINUE_SNAPSHOT_PATH = '/continue-snapshot/:snapshot_uuid';
export const SNAPSHOT_SIGNUP_PATH = '/authentication/snapshot-signup/';

export const ACCOUNT_SETTINGS_PATH = '/account-settings';
