import { ChevronDown, ChevronUp, CodeBlock } from '@carbon/icons-react';
import { Button } from '@carbon/react';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rehypeKatex from 'rehype-katex';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import './ConversationMessageUserTool.scss';

import { MemoizedReactMarkdown } from 'components/markdown/memoized-react-markdown';
import { prepareLatexContent } from 'utils/latexPreprocessor';

export const ConversationMessageUserTool = ({ schema, data }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const messageThreshold = 500;

  useEffect(() => {
    const contentLength = Object.values(data).reduce((acc, value) => acc + String(value).length, 0);
    setShouldCollapse(contentLength > messageThreshold);
  }, [data]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className='conversation-message-user-tool'>
      <p>{schema.title}</p>
      <div className={`tool-content ${shouldCollapse && !isExpanded ? 'collapsed' : ''}`}>
        {map(schema.properties, (property, key) => {
          const label = property.title;
          const value = data[key];

          return (
            <div className='schema-property' key={`${label}-${value}`}>
              <div className='label'>{label}</div>
              <div className='value cds--text-area text'>
                <MemoizedReactMarkdown
                  remarkPlugins={[remarkGfm, remarkMath, remarkBreaks]}
                  rehypePlugins={[rehypeKatex]}
                  components={{
                    code({ inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '');
                      return !inline ? (
                        <CodeBlock
                          key={Math.random()}
                          language={(match && match[1]) || ''}
                          value={String(children).replace(/\n$/, '')}
                          {...props}
                        />
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                  }}>
                  {prepareLatexContent(value)}
                </MemoizedReactMarkdown>
              </div>
            </div>
          );
        })}
      </div>
      {shouldCollapse && (
        <Button
          onClick={toggleExpand}
          kind='ghost'
          size='sm'
          className='show-more-less-button'
          renderIcon={isExpanded ? ChevronUp : ChevronDown}>
          {isExpanded ? t('Show less') : t('Show more')}
        </Button>
      )}
    </div>
  );
};

ConversationMessageUserTool.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
};
