import { PropTypes } from 'prop-types';
import React, { createContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useWebSocket from 'react-use-websocket';

import { WS_CHAT } from 'constants/constants';
import { buildUrl } from 'utils/utils';

const MAX_RECONNECT_INTERVAL = 10000;

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const url = buildUrl(WS_CHAT).replace('http', 'ws');
  const activeToastId = useRef(null);
  const successToastId = useRef(null);
  const { t } = useTranslation();

  const websocket = useWebSocket(url, {
    shouldReconnect: () => true,
    reconnectAttempts: Number.MAX_SAFE_INTEGER,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, MAX_RECONNECT_INTERVAL),
    retryOnError: true,
    onOpen: () => {
      if (activeToastId.current) {
        toast.update(activeToastId.current, {
          render: t('Connection restored'),
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
        });
        successToastId.current = activeToastId.current;
        activeToastId.current = null;
      }
    },
    onError: () => {
      if (successToastId.current) {
        toast.dismiss(successToastId.current);
        successToastId.current = null;
      }

      if (!activeToastId.current) {
        activeToastId.current = toast.loading(t('Attempting to reconnect...'), {
          position: 'top-right',
          autoClose: false,
          draggable: false,
          closeOnClick: false,
          closeButton: false,
        });
      }
    },
  });

  return <WebSocketContext.Provider value={websocket}>{children}</WebSocketContext.Provider>;
};

WebSocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
